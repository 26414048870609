import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import trafikArtis from "../../assets/img/kb-mobile.png"
import sorunlar from "../../assets/img/kb-sorunlar.png"
import kuruyemisborsasi from "../../assets/img/kb-new.png"
import cozum from "../../assets/img/kb-cozumler.png"
import OtherCaseStudy from "../../components/other-case"

import aysima from "../../assets/img/teams/35.jpg"
import myildirim from "../../assets/img/teams/22.jpg"
import serife from "../../assets/img/teams/20.jpg" 
import bengu from "../../assets/img/teams/17.jpg"
import zeynep from "../../assets/img/teams/33.jpg"




const ReferancesDetail = () => (
    <Layout>
        <SEO title="Kuruyemiş Borsası Başarı Hikayesi" />
        <div className="referances-detail py60 container">
            <div className="col-md-6 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1>Kuruyemiş Borsası</h1>
                <p style={{ maxWidth: 450 }}>1994 yılından bu yana kuruyemiş sektöründe faaliyet gösteren köklü bir kuruluş ile dijital pazarlama alanında büyük başarılara imza atan bir ekibin ortaya koyduğu Kuruyemiş Borsası, doğanın bizlere sunmuş olduğu en cömert hediyelerden biri olan kuruyemiş çeşitlerini e-ticaret platformu üzerinden alıcılarına ulaştırmaktadır. 
</p>
                <p style={{ maxWidth: 450 }}>flatart olarak Kuruyemiş Borsası için en başta marka stratejisini kurguladıktan sonra websitesi tasarımı, SEO ve SEM alanlarında çalışmalar yürüttük gereken noktada website iyileştirmelerini hayata geçirerek sürdürülebilir bir başarı grafiği elde etmeye odaklandık.</p>
                <a className="svgLink" target="_blank" href="https://www.kuruyemisborsasi.com/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                    <g id="Group_11" data-name="Group 11" transform="translate(-432 -2296)">
                        <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(432 2296)" fill="none" stroke="#000" stroke-width="3">
                            <circle cx="24" cy="24" r="24" stroke="none" />
                            <circle cx="24" cy="24" r="22.5" fill="none" />
                        </g>
                        <path id="Path_80" data-name="Path 80" d="M422.384,556.893l-2.14,2.154,7.736,7.846-7.736,7.846,2.14,2.154,9.86-10Z" transform="translate(30.756 1753.107)" />
                    </g>
                </svg>
                    <span>kuruyemisborsasi.com</span></a>
               
            </div>
            <div className="col-md-6 referances-boxs">
                <div className="boxs" style={{ marginBottom: '0' }}>
                    <div className="box">
                        <span>+%186</span>
                        <p>Dönüşüm oranı artışı.</p>
                    </div>
                    <div className="box">
                        <span>+%34</span>
                        <p>Yıllık ciro artışı</p>
                        
                    </div>
                    <div className="box">
                        <span>+%18</span>
                        <p>Oturum süresi artışı.</p>
                    </div>

                </div>
                <div className="provided">
                    <h3>Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/ui-ux-tasarim/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Design-_-Sketch" data-name="Icons-/-Design-/-Sketch" transform="translate(0.015 -0.307)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.015 0.307)" fill="none" />
                                    <path id="Path-48" d="M13.728,3H58.276L71,18.91H1Z" transform="translate(2.182 6.546)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Path-48-Copy" d="M71,8,36,46.184,1,8Z" transform="translate(2.182 17.456)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            UI&UX Tasarım
                        </div>
                        <div className="box">
                            <Link to="/arama-motoru-optimizasyonu/"></Link>
                            <svg style={{ marginLeft: -7 }} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 79 80">
                                <g id="Icons-_-Shopping-_-Chart-line_1" data-name="Icons-/-Shopping-/-Chart-line#1" transform="translate(-0.462 0.373)">
                                    <rect id="bound" width="79" height="80" transform="translate(0.462 -0.373)" fill="none" />
                                    <path id="Path-95" d="M9.6,55.773H59.071a3.3,3.3,0,0,1,0,6.6H6.3a3.3,3.3,0,0,1-3.3-3.3V6.3a3.3,3.3,0,0,1,6.6,0Z" transform="translate(6.895 7.094)" fill="#000000" />
                                    <path id="Path-97" d="M12.7,33.493a3.3,3.3,0,0,1-4.812-4.512L20.261,15.788a3.3,3.3,0,0,1,4.547-.253l9.762,8.33L47.289,7.755a3.3,3.3,0,1,1,5.178,4.088l-14.842,18.8a3.3,3.3,0,0,1-4.73.465L22.92,22.6Z" transform="translate(16.088 15.138)" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                            SEO
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Proje Yöneticisi:</li>
                            <li>SEO Uzmanı:</li>
                            <li>Dijital Reklam Uzmanı:</li>
                            <li>İçerik Yazarı:</li>
                            <li>Website Yazılım Geliştirme:</li>
                            
                        </ul>
                        <ul>
                            <li><img src={myildirim} alt="Murthan Yıldırım" />Murathan Yıldırım</li>
                            <li><img src={bengu} alt="Bengü Dinçer" />Bengü Dinçer</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                            <li><img src={aysima}  alt="Aysima Bakırcılar" />Aysima Bakırcılar</li>
                            <li><img src={zeynep} alt="Zeynep Oralel" />Zeynep Oralel</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
        <div className="content-section content row py60" >
                <div className="col-md-7">
                    <img  src={kuruyemisborsasi} alt="kuruyemiş borsası web"/>
                </div>
                <div className="col-md-5">
                    <span className="small">Başarı Hikayesi</span>
                    <p style={{ maxWidth: 400 }} >Çalışmaya başladığımız günden bugüne marka bilinirliğini önemli ölçüde artırmış olduğumuz Kuruyemiş Borsası’nın eriştiği kitleyi artırırken tekrarlayan sipariş adetlerinin de katlanmasını sağladık.</p>
                   
                </div>
            </div>
            <div className="content-section content row" id="eCantaCozum">
                <div className="col-md-6">
                    <span className="small">Başarı Hikayesi</span>
                    <h3>Sorunlar</h3>
                    <p style={{ maxWidth: 450 }} >- Edinilen trafiğe göre e-ticaret dönüşüm oranının düşük olması,</p>
                    <p style={{ maxWidth: 450 }} >- Aylık ciro artışının istenen seviyede olmaması,</p>
                    <p style={{ maxWidth: 450 }} >- Arama sonuçlarında website görünürlüğünün istendiği gibi olması,</p>
                    <p style={{ maxWidth: 450 }} >- Ortalama oturum süresinin ideal düzeyin altında olması.</p>
                </div>
                <div className="col-md-6">
                    <img src={sorunlar} alt="desktop menü ekranı"/>
                </div>
            </div>
            <div className="content-section content row py60" >
                <div className="col-md-6">
                    <img className="pr100" src={cozum} alt="desktop menü ekranı"/>
                </div>
                <div className="col-md-6">
                    <span className="small">Başarı Hikayesi</span>
                    <h3>Çözümler</h3>
                    <p style={{ maxWidth: 500 }} >- Website tasarımı daha kullanıcı odaklı olacak biçimde geliştirildi.</p>
                    <p style={{ maxWidth: 500 }} >- Marka sadakati için stratejiler oluşturuldu ve hayata geçirildi.</p>
                    <p style={{ maxWidth: 500 }} >- Kullanıcı eğilimleri odağında SEO içerikleri kurgulandı.</p>
                    <p style={{ maxWidth: 500 }} >- Teknik SEO isterleri tamamlandı.</p>
                    </div>
            </div>

            <div className="text-center content py60">
                <span className="small">Başarı Hikayesi</span>
                <h2 style={{ margin: '7px auto 10px', maxWidth: 900 }}><b>1 yılda</b> organik trafik aracılığı ile <b> %186</b>  e-ticaret dönüşüm oranı artışı elde edildi</h2>
                <p style={{ maxWidth: 1100 }} >SEO odaklı içerik pazarlama stratejisi sayesinde yüksek kaliteli trafik elde edildi. Bu sayede organik satışlar gerçekleştirildi ve Ağustos 2020 - Ağustos 2021 döneminde, bir önceki döneme kıyasla e-ticaret dönüşüm oranında %186 oranında artış yakalandı.</p>
            </div>

            <div className="content-section content row" >
                <div className="col-md-6">
                    <img  src={trafikArtis} alt="iki laptop bilgisayar ekranında tcdd taşımacılık web sitesi görüntüleniyor" />
                </div>
                <div className="col-md-6">
                   
                    <h3 style={{ maxWidth: 480, fontSize: 25, textAlign:"right" }}>Organik trafik aracılığı ile elde edilen yıllık ciroda <b>%34 artış yakalandı</b></h3>
                    <p style={{ maxWidth: 480, textAlign:"right" }} >Website tasarımının kullanıcı ihtiyaçlarına göre yeniden kurgulanması ve teknik SEO gereksinimlerinin tamamlanması ile yıllık cironun Ağustos 2020 - Ağustos 2021 döneminde, bir önceki döneme göre %34 oranında artması sağlandı.</p>
                    <h3 style={{ maxWidth: 480, fontSize: 25, textAlign:"right"}}>  <b>1 yılda </b> ortalama oturum süresi  <b>%18 artırıldı. </b></h3>
                    <p style={{ maxWidth: 480,  textAlign:"right"}} >SEO odaklı içerik pazarlama çalışmaları ve teknik SEO gereksinimlerinin sağlanması ile internet kullanıcılarının beklentilerine uygun bir web deneyimi sunuldu. Bu da online ziyaretçilerin Kuruyemiş Borsası’nın websitesinde daha fazla zaman geçirmelerine önayak oldu. Geldiğimiz noktada, Ağustos 2020 - Ağustos 2021 döneminde, bir önceki döneme kıyasla ortalama oturum süresinde %18 artış elde edildi.</p>
                    
                </div>
               
            </div>

        </div>

        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
